import React, { useMemo } from "react";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { Title } from "../../components/atoms/Title/title";
import CardsPassport from "../../components/molecules/CardsPassport";
import { useStaticQuery, graphql } from "gatsby";
import { cardsPassportData } from "../../data/requirements";
import { buildPassportCardsData } from "../../components/utils/landingPages";

const Requirements = () => {
  const data = useStaticQuery(getContentfulData);

  const parsedCardsPassportData = useMemo(() => {
    const cards = cardsPassportData("default");
    return buildPassportCardsData(cards, data.allContentfulFormType.nodes);
  }, [data.allContentfulFormType.nodes]);

  return (
    <Layout>
      <Wrapper marginTop>
        <Title
          hero
          tag="h1"
          content={"Requirements for each form"}
        />
        <CardsPassport
          cards={parsedCardsPassportData}
          isLanding={true}
          source="landing-requirements"
        />
      </Wrapper>
    </Layout>
  );
};

const getContentfulData = graphql`
  query {
    allContentfulFormType(filter: { show: { eq: true }, node_locale: {eq: "en-US"} }) {
      nodes {
        slug
      }
    }
  }
`;

export default Requirements;
