export const cardsPassportData = () => {
  let cards = [
    {
      id: "6bc785f3-7b63-506f-aa47-45191ac91a44",
      show: true,
      name: "DS-11",
      headerTitle: "New Passport <span style='white-space: nowrap;'>DS-11</span>",
      formButtonText: "Complete DS-11 online",
      slug: "new-passport",
      formImage: {
        id: "dde49d38-39b0-52e0-9d25-849507a8cf4d",
        url: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/5pHNEe69EAvx37ZkDd8PGh/4022fd857476ddb3979ed3bdab781972/DS-82-1.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Start now if the following applies: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "You are applying OR renewing a passport for someone under 16",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "It is your first U.S. passport.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Your previous U.S. passport was issued over 15 years ago.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "If you are over 16 and were aged 15 or under when you were issued your last passport.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 2,
      orderByTemplate: {
        default: 2,
        template_1: 1,
      },
    },
    {
      id: "fbe3d439-9806-5b6f-96d3-3e0d48332492",
      show: true,
      name: "DS-82",
      headerTitle: "Passport Renewal <span style='white-space: nowrap;'>DS-82</span>",
      formButtonText: "Complete DS-82 online",
      slug: "passport-renewal",
      formImage: {
        id: "7ab7d93c-a65a-5a10-be6b-a53944a7605e",
        url: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/JGYyuMsNWCAVvDCvq8uL9/f2b2e587a1f25f8378b86bfaa857e69f/DS-11__1_.png?w=95&h=123&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Start a DS-82 now if your previous passport:",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Is in your possession and is undamaged.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Was issued when you were aged 16 or older.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Has few blank passport pages remaining.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Will expire soon or has expired in the last 5 years.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Was issued 1+ years ago and you have since legally changed your name.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 1,
      orderByTemplate: {
        default: 1,
        template_1: 2,
      },
    },
    {
      id: "fc8e81b7-09c9-5cb0-bcd3-da26d380677c",
      name: "DS-64",
      headerTitle: "Lost or Stolen Passport <span style='white-space: nowrap;'>DS-64</span>",
      formButtonText: "Complete DS-64 online",
      slug: "lost-stolen-passport",
      show: true,
      formImage: {
        id: "e8a3adb2-ccec-51d8-8274-72c3ae9e1b27",
        url: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/976723a740a93fa3c8fbbbc8829773a3/DS-64.png",
        fluid: {
          sizes: "(max-width: 800px) 100vw, 800px",
          src: "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/976723a740a93fa3c8fbbbc8829773a3/DS-64.png?w=800&q=50",
          srcSet:
            "//images.ctfassets.net/otm3aqjb1k5c/nU6flSQ4NpMhh9cFQYzqs/976723a740a93fa3c8fbbbc8829773a3/DS-64.png?w=95&h=122&q=50 95w",
        },
      },
      type: true,
      requeriments: {
        raw: {
          nodeType: "document",
          data: {},
          content: [
            {
              nodeType: "heading-3",
              content: [
                {
                  nodeType: "text",
                  value: "Start a DS-64 now if your passport: ",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "unordered-list",
              content: [
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value: "Was lost or stolen and you wish to report the loss.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
                {
                  nodeType: "list-item",
                  content: [
                    {
                      nodeType: "paragraph",
                      content: [
                        {
                          nodeType: "text",
                          value:
                            "Was lost or stolen and you wish to report the loss AND apply for a new passport.",
                          marks: [],
                          data: {},
                        },
                      ],
                      data: {},
                    },
                  ],
                  data: {},
                },
              ],
              data: {},
            },
            {
              nodeType: "paragraph",
              content: [
                {
                  nodeType: "text",
                  value: "",
                  marks: [],
                  data: {},
                },
              ],
              data: {},
            },
          ],
        },
      },
      order: 3,
      orderByTemplate: {
        default: 3,
        template_1: 3,
      },
    },
  ];

  // TODO: Sort array cards by defined attributes
  return cards.sort((item, prevItem) => {
    const template = "default";

    if (typeof item.orderByTemplate === "object") {
      return item.orderByTemplate[template] - prevItem.orderByTemplate[template];
    } else {
      return item.order - prevItem.order;
    }
  });
};
